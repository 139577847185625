import React from "react"
import Header from "./Header"

function AboutPage() {
  return (
    <>
      <Header />
      <div className="belowHeader">
        <div className="bigHeader">About</div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </div>
    </>
  )
}

export default AboutPage
